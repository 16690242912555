import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Background, Product } from '../../lib/types';

interface UIState {
  bg: Background;
  navbar: {
    logo: {
      current: Product;
      isWhite?: boolean;
    };
  };
}

const initialState: UIState = {
  bg: Background.Main,
  navbar: {
    logo: {
      current: Product.Arteria,
    },
  },
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    changeBackground(state: UIState, action: PayloadAction<Background>) {
      state.bg = action.payload;
    },
    changeCurrentLogo(
      state: UIState,
      action: PayloadAction<{ product: Product; isWhite?: boolean }>,
    ) {
      const { isWhite } = action.payload;
      state.navbar.logo = { isWhite, current: action.payload.product };
    },
  },
});

export const { changeBackground, changeCurrentLogo } = uiSlice.actions;

export default uiSlice.reducer;
