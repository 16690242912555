exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bridge-tsx": () => import("./../../../src/pages/bridge.tsx" /* webpackChunkName: "component---src-pages-bridge-tsx" */),
  "component---src-pages-connect-index-tsx": () => import("./../../../src/pages/connect/index.tsx" /* webpackChunkName: "component---src-pages-connect-index-tsx" */),
  "component---src-pages-connect-pricing-tsx": () => import("./../../../src/pages/connect/pricing.tsx" /* webpackChunkName: "component---src-pages-connect-pricing-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-spei-tsx": () => import("./../../../src/pages/spei.tsx" /* webpackChunkName: "component---src-pages-spei-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

