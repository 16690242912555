import { extendTheme } from '@chakra-ui/react';

const colors = {
  // Azul Arteria
  brand: {
    50: '#E8F1FD',
    100: '#BDD8F9',
    200: '#93BFF5',
    300: '#69A5F2',
    400: '#3F8CEE',
    500: '#1573EA',
    600: '#115CBB',
    700: '#0C458D',
    800: '#093266',
    900: '#04172F',
  },
  // Magenta Arteria
  brandSecondary: {
    50: '#F9EBF4',
    100: '#EFC7E0',
    200: '#E5A3CC',
    300: '#DB80B8',
    400: '#CE509D',
    500: '#C7388F',
    600: '#9F2D73',
    700: '#782156',
    800: '#501639',
    900: '#280B1D',
  },
  // Naranja Arteria
  brandAccent: {
    50: '#FFF3E5',
    100: '#FFDDB8',
    200: '#FFC78A',
    300: '#FFB25C',
    400: '#FF9015',
    500: '#FF8600',
    600: '#CC6B00',
    700: '#995000',
    800: '#663600',
    900: '#331B00',
  },
  // Violeta Arteria
  brandViolet: {
    50: '#F1ECF9',
    100: '#D9C9ED',
    200: '#C0A7E2',
    300: '#9164CC',
    400: '#8F62CB',
    500: '#773FC0',
    600: '#5F3399',
    700: '#472673',
    800: '#30194D',
    900: '#180D26',
  },
  brown: '#3B2314',
  // Negro Arteria
  black: '#1C2B39',
  void: '#000000',
  // Blanco Arteria
  white: '#FFFFFF',
  brandError: '#D2616F',
  // Amarillo stars
  stars: '#FFC74A',
};

const fonts = {
  heading: 'JakartaDisplay, normal',
  body: 'JakartaText, norma',
};

const shadows = {
  purple:
    'rgba(127, 17, 224, 0.0) 0px 0px 0px 100px, rgba(127, 17, 224, 0.7) 0px 0px 120px, rgba(127, 17, 224, 0.8) 0px 0px 150px',
  blue: 'rgba(9, 50, 102, 0.0) 0px 0px 0px 100px, rgba(9, 50, 102, 0.7) 0px 0px 120px, rgba(9, 50, 102, 0.8) 0px 0px 150px',
  yellow:
    'rgba(255, 144, 21, 0) 0px 0px 0px 100px, rgba(255, 144, 21, 0.6) 0px 0px 120px, rgba(255, 144, 21, 0.8) 0px 0px 150px',
};

const components = {
  Divider: {
    defaultProps: { size: 'md' },
    sizes: {
      lg: { borderWidth: '1.5px' },
      md: { borderWidth: '1.5px' },
      sm: { borderWidth: '1.5px' },
    },
  },
};

const breakpoints = {
  base: '0px',
  ms: '320px',
  mm: '370px',
  mt: '390px',
  ml: '425px',
  sm: '480px',
  sg: '720px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  xll: '1366px',
  x3l: '1440px',
  '2xl': '1536px',
  '3xl': '1824px',
  '4xl': '2112px',
};

export default extendTheme({ breakpoints, colors, fonts, shadows, components });
