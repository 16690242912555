import { Middleware, MiddlewareArray, configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import ui from '../reducers/ui';

const middlewares: Middleware[] = [];

if (process.env.NODE_ENV === 'development') middlewares.push(logger);

const store = configureStore({
  reducer: {
    ui,
  },
  middleware: () => [...new MiddlewareArray().concat(middlewares)],
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export default store;
