export enum Background {
  Main = 'void',
  Secondary = 'brand.800',
  Connect = '#3D0966',
  Pricing = 'white',
}

export enum Product {
  Arteria = 'arteria',
  Bridge = 'bridge',
  Connect = 'connect',
}

export enum ZPosition {
  Background = -1000,
  Last = -4,
  SecondLast = -3,
  Behind = -2,
  Normal = 0,
  Front = 1,
  Above = 2,
  InHeaven = 3,
}

export enum ZPositionBehind {
  First = -5,
  Second = -4,
  Third = -3,
  Fourth = -2,
  Fifth = -1,
}
